import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import { formatDate } from '../../components/dateFormate'
import { AlignCenter } from "react-bootstrap-icons";


const ViewAllInvoices = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);


  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadStaffData();
    }
  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `/getAllInvoice?Org_Id=${userData.Org_Id}`
      );
      setStaffData(result.data || []);
      console.log(result.data[0]);
      setStaffDataa(result.data || []);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };


  const columns = [
    {
      name: "Invoice No.",
      cell: (row) => (
        <Link to={`/invoice/${row.Enq_Id}/Facility`} target="_blank">
          {row.Invoice_No}
        </Link>
      ),
      width: "100px",
    },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "290px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name}

          </span>
        </LightTooltip>
      ),
      width: "290px",
    },
    { name: "Name", selector: (row) => row.Customer_Name },
    { name: "Certificate", selector: (row) => row.Certificate_Name },
    // { name: "Gvt. Amt.", selector: (row) => row.Gvt_Amount, width: "90px"},
    // { name: "GST Amt.", selector: (row) => row.Gst_Amount, width: "90px" },
    // { name: "Consultation Amt.", selector: (row) => row.Total_Consultation, width: "140px" },
    { name: "Net Amt.", selector: (row) => row.Net_Amount, width: "90px" }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape'
    });
    doc.text("Invoice Details", 20, 10);

    const columns = [
      "Invoice No.",
      "Invoice Date",
      "Company Name",
      "Customer Name",
      "Certificate Name",
      "Government Charges",
      "Consultation Charges",
      "GST Amount",
      "Total Consultation",
      "Net Amount",
    ];

    const rows = data.map((row) => [
      row.Invoice_No,
      formatDate(row.Invoice_Date),
      row.Company_Name,
      row.Customer_Name,
      row.Certificate_Name,
      row.Gvt_Amount,
      row.Consultation_Charges,
      row.Gst_Amount,
      row.Total_Consultation,
      row.Net_Amount,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("InvoiceDetails.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Invoice No.",
      "Invoice Date",
      "Company Name",
      "Customer Name",
      "Certificate Name",
      "Government Charges",
      "Consultation Charges",
      "GST Amount",
      "Total Consultation",
      "Net Amount",
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Invoice_No,
        formatDate(row.Invoice_Date),
        row.Company_Name,
        row.Customer_Name,
        row.Certificate_Name,
        row.Gvt_Amount,
        row.Consultation_Charges,
        row.Gst_Amount,
        row.Total_Consultation,
        row.Net_Amount,
      ]);
    });
    console.log("excel " + rows)
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Invoice Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "InvoiceDetails.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
        (row.Role && row.Role.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue))
      );
    });
    setStaffData(filteredData);
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Invoice Details</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default ViewAllInvoices;
